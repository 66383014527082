import styled, { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils/withDimensionsCSS';
import {
  withFontCSS,
  WithFontProps,
} from 'app/shared/components/styleUtils/withFontCSS';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils/withSpacingCSS';

export interface TypographyProps
  extends WithSpacingProps,
    WithFontProps,
    WithDimensionsProps {
  theme: CommonTheme;
  display?: string;
  color?: string;
}

export const Typography = styled.p<TypographyProps>`
  ${({ theme, color, display = 'inline-block' }) => css`
    color: ${color ? color : theme.colors.primary.black.base};
    display: ${display};
    margin: 0;
    padding: 0;
  `}

  ${withSpacingCSS};
  ${withFontCSS};
  ${withDimensionsCSS};
`;
