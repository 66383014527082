import React from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'app/shared/components/atoms/Typography';

const SectionHeaderContainer = styled.span<{
  invertColors?: boolean;
}>`
  ${({ theme, invertColors }) => css`
    padding: ${theme.ruler[3]}px ${theme.ruler[4]}px;
    background: ${invertColors
      ? theme.colors.primary.black.base
      : theme.colors.primary.white.base};
    position: sticky;
    display: block;
    top: 0;

    color: ${invertColors
      ? theme.colors.accent.primaryAccent.base
      : theme.colors.primary.black.base};
  `}
`;

export const DropdownSectionHeader = ({
  children,
  ...rest
}: {
  children: any;
  invertColors?: boolean;
  showTopAccent?: boolean;
}) => {
  return (
    <SectionHeaderContainer {...rest}>
      <Typography
        as="div"
        fontSize="fontSize75"
        textTransform="uppercase"
        color="inherit"
        fontWeight="medium"
      >
        {children}
      </Typography>
    </SectionHeaderContainer>
  );
};
